import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGetInvoice } from '../../api';
import { colors } from '../../assets/colors';
import { AnimatedLoader, Attachments, Button, Error, RoundBox } from '../../components';
import { BankIDLogo } from '../../components/Logos';
import { Clamp, Text } from '../../helpers';
import { setGlobalState, useGlobalState } from '../../state';
import { AttachmentFailedComponent } from './AttachmentsSigningFailed';
import { AttachmentSuccessComponent } from './AttachmentsSigningSuccess';


const Wrapper = styled.div`
    margin-top: 10%;
`;
const AttachmentSigning = () => {
    const { invoice_id } = useParams();

    const [invoice] = useGlobalState('invoice');
    const [loading] = useGlobalState('loading')
    const location = useLocation();
    const { fetchedInvoice } = useGetInvoice(invoice_id);


    const statusHandler = () => {
        switch (true) {
            case location.state?.failed === 'redirected':
                return <AttachmentFailedComponent />;
            case location.state?.succeed === 'redirected':
                return <AttachmentSuccessComponent />;
            default:
                return (
                    <RoundBox style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
                        <Text i="Signera dokument" fontWeight="bold" header />
                        <Text i="Du har ett dokument att signera." />
                    </RoundBox>
                );
        }
    };
    return (
        <Wrapper>
            {loading && <AnimatedLoader />}
            {fetchedInvoice && (
                <>
                    {!invoice.created ? (
                        <Error message="Invoice not found" />
                    ) : (
                        <Clamp>
                            {statusHandler()}
                            {invoice.attachments[0].storage_name ? (
                                <RoundBox>
                                    <Attachments {...invoice} />
                                </RoundBox>
                            ) : (
                                <Error
                                    message="There is nothing to sign"
                                    nf={false}
                                    textStyle={{
                                        fontSize: 25,
                                        textAlign: 'center',
                                        color: 'red',
                                        marginTop: 0
                                    }}
                                />
                            )}
                            {invoice.attachments[0].storage_name && !location.state?.succeed ? (
                                <Button
                                    i="Signera med BankID "
                                    color={colors.white}
                                    fontWeight="bold"
                                    style={{
                                        marginTop: '8%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '15px'
                                    }}
                                    disabled={invoice.attachments.length === 0}
                                    onClick={async () => {
                                        setGlobalState('loading', true);
                                        await axios
                                            .post(
                                                process.env.REACT_APP_API_BASE_URL +
                                                    'api/attachment/sign/' +
                                                    invoice?.attachments[0].storage_name +
                                                    '/sign_attachment/',
                                                {
                                                    signee: 'fast'
                                                }
                                            )
                                            .then((res) => (window.location.href = res.data.url))
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                        setGlobalState('loading', false);
                                    }}>
                                    <BankIDLogo />
                                </Button>
                            ) : (
                                <></>
                            )}
                            <Button
                                i="Back to app"
                                color={colors.white}
                                fontWeight="bold"
                                style={{
                                    marginTop: '8%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '15px',
                                    background: '#454f63'
                                }}
                                onClick={async () => {
                                    isMobile
                                        ? (window.location.href = 'com.dicopaywebapp://')
                                        : window.open('https://webapp-staging.dicopay.com/');
                                }}></Button>
                        </Clamp>
                    )}
                </>
            )}
        </Wrapper>
    );
}

export default AttachmentSigning


