import styled from 'styled-components';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { RowView, Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';
import { colors } from '../../assets/colors';
import { IInvoice } from '../../interfaces';
import { dateTransform } from '../../functions';

const AttachmentList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style: none;
    margin-top: 15px;
`;

const AttachmentListItem = styled.li`
    padding: 12px 16px;
    margin-bottom: 8px;
    background: #fefefe;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }
`;

const IconTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledLink = styled.a`
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-decoration: none;
`;

const AttachmentsDetails = (invoice: IInvoice) => {

    if (!invoice.attachments || invoice.attachments.length === 0) {
        return null;
    } else {
        return (
            <RoundBox>
                <Text i="Bilagor" fontWeight="bold" header />
                <AttachmentList>
                    {invoice.attachments.map(
                        ({ file_name, uploaded_at, file_url }, index) => (
                            <AttachmentListItem key={`${file_name + index}`}>
                                <RowView>
                                    <StyledLink
                                        href={file_url}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <IconTextWrapper>
                                            <PictureAsPdfIcon sx={{ color: colors.main }} />
                                            <Text i={file_name} />
                                        </IconTextWrapper>
                                        <Text i={`${dateTransform(uploaded_at, invoice)}`} />
                                    </StyledLink>
                                </RowView>
                            </AttachmentListItem>
                        )
                    )}
                </AttachmentList>
            </RoundBox>
        );
    }
};

export default AttachmentsDetails;
