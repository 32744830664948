import styled from 'styled-components';
import { ReactElement } from 'react';
import { DicoLogo } from '../Logos';
import SnowBackgroundWebp from '../../assets/images/SnowBackground.webp'; 
import BackgroundImagePng from '../../assets/images/background_image.png';
import { useLocation } from 'react-router-dom';

type WrapperProps = {
    merchant: boolean
}

const Wrapper = styled.header`
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    z-index: -2;
`;

const ColorStrip = styled.div<WrapperProps>`
    width: 100%;
    height: 80px;
    background: ${(props) =>
    props.merchant ? '#2A2E43' : 'linear-gradient(116deg, #ed1e79, #9e005d);'};
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;
`;

const BackgroundImageContainer = styled.div`
    display: block;
    width: 100%;
    height: 300px;
    position: relative; /* Important for absolute positioning of the image */
`;

const Header = (): ReactElement => {
const {pathname} = useLocation()
  const merchant = pathname.includes("attachments")
    return (
        <Wrapper>
            <ColorStrip merchant={merchant} />
            <DicoLogo width="200px" style={{ position: 'absolute', top: 18, left: 25 }} />
            <BackgroundImageContainer>
                <picture>
                    <source srcSet={SnowBackgroundWebp} type="image/webp" />
                    <img
                        src={BackgroundImagePng}
                        alt="Background"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </picture>
            </BackgroundImageContainer>
        </Wrapper>
    );
}
export default Header;
