import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoundBox } from '../../components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Text } from '../../helpers';

export const AttachmentsSigningSuccess = () => {
    const { invoice_id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/invoice/attachments/${invoice_id}/`, {
            replace: true,
            state: {
                succeed: 'redirected'
            }
        });
    });

    return <></>;
};


const IconTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 35px;
`;

export const AttachmentSuccessComponent = () => {
    return (
        <RoundBox
            style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                alignItems: 'flex-start',
                flexDirection: 'column',
                padding: '24px',
                marginTop: '5vh',
            }}>
            <IconTextWrapper>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon fontSize="large" style={{ fill: 'green' }} />
                </span>
                <Text
                    i="Klart"
                    fontWeight="600"
                    color={colors.dark}
                    style={{
                        textAlign: 'center',
                        fontSize: '24px',
                        lineHeight: '35px'
                    }}
                />
            </IconTextWrapper>
            <Text i="Fakturan undertecknades framgångsrikt" color={colors.lightGrey} />
        </RoundBox>
    );
};