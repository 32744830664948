import { useParams, useLocation } from 'react-router-dom';
import { useGetInvoice } from '../../api';
import {
    AnimatedLoader,
    CustomerDetails,
    Error,
    DueDays,
    InvoiceDetails,
    InvoiceStatus,
    MobileAppsButtons,
    Attachments,
} from '../../components';
import { Clamp } from '../../helpers';
import { useGlobalState } from '../../state';

const Checkout = () => {
    const { invoice_id } = useParams();
    const { fetchedInvoice } = useGetInvoice(invoice_id);
    const [invoice] = useGlobalState('invoice');
    const location = useLocation();


    // Renders components after backend redirection
    const renderOption = () => {
        switch (true) {
            case !fetchedInvoice:
                return <AnimatedLoader />;
            case location.state?.failed === 'redirected' && fetchedInvoice && invoice.is_signed:
                return (
                    <Clamp>
                        <InvoiceStatus status="success" />
                        <CustomerDetails />
                    </Clamp>
                );
            case location.state?.failed === 'redirected' && fetchedInvoice && !invoice.is_signed:
                return (
                    <Clamp>
                        <InvoiceStatus status="failed" />
                        <CustomerDetails status="failed" />
                        {/* <PaymentMethods /> */}
                        {/* <BankIDButton /> */}
                        <DueDays invoiceId={invoice.uuid} />
                        <InvoiceDetails />
                        <Attachments {...invoice} />
                    </Clamp>
                );
            case fetchedInvoice && invoice.is_signed === null:
                return <Error message="Invoice not found" />;
            case fetchedInvoice && invoice.status === 'cancelled':
                return (
                    <Error
                        invoice={invoice}
                        message="This invoice is cancelled"
                        reason="cancelled"
                    />
                );
            case invoice.is_signed:
                return (
                    <Clamp>
                        <InvoiceStatus status="success" />
                        <CustomerDetails />
                        <Attachments {...invoice} />
                        <MobileAppsButtons />
                    </Clamp>
                );
            case !invoice.is_signed:
                return (
                    <Clamp>
                        <CustomerDetails />
                        <DueDays invoiceId={invoice.uuid} />
                        <InvoiceDetails />
                        <Attachments {...invoice} />
                    </Clamp>
                );
            default:
                return <Error message="Something went worng" />;
        }
    };

    return <>{renderOption()}</>;
};

export default Checkout;
