import axios from 'axios';
import { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { RowView, Text } from '../../helpers';
import { IInvoice } from '../../interfaces';
import { setGlobalState, useGlobalState } from '../../state';
import AnimatedLoader from '../AnimatedLoader/AnimatedLoader';
import RoundBox from '../RoundBox/RoundBox';
import { dateTransform } from '../../functions';

const Wrapper = styled.div``;

interface DueDaysProps {
    selected?: boolean;
}

type DueDays = {
    invoiceId: IInvoice['uuid'];
};



type DueDaysOption = {
    due_days: number;
    intent_uuid?: string; // Made intent_uuid optional
    cost_excl_vat_amount: 0 | string;
    vat_cost: 0 | string;
};

const Dot = styled.div<DueDaysProps>`
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 1vw;
    border-width: ${(props) => (props.selected ? '6px' : '1px')};
    border-style: solid;
    border-color: ${(props) => (props.selected ? colors.main : colors.grey)};
    border-radius: 100%;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const DaysAmount = styled.div<DueDaysProps>`
    background: ${colors.faded};
    padding: 24px;
    border-radius: 6px;
    margin-top: 2vh;
    opacity: ${(props) => (props.selected ? 1 : 0.7)};
    border-width: 0.5;
    border-color: ${(props) => (props.selected ? colors.main : colors.white)};
    border-style: solid;
    box-sizing: content-box;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

const DueDays = ({ invoiceId }: DueDays) => {
    const CHANGE_KR_TO_PERCENTAGE = 10000;

    const [invoice] = useGlobalState('invoice');
    const [loading] = useGlobalState('loading');
    const [dueDaysOptions, setDueDaysOption] = useState<DueDaysOption[]>([]);
    const [selectedDueDays, setSelectedDueDays] = useState(0);

    const iniTialTotalAmount = useMemo(() => {
        return invoice.total_amount;
    }, [invoice.uuid]);

    const addDays = (date: Date | undefined | null, days: number) => {
        if (!date) {
            return;
        }
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        const formatedDueDate = result.toUTCString();
        const i = formatedDueDate.indexOf(' ');
        return formatedDueDate.slice(i, formatedDueDate.length - 12);
    };

    // Fetch BNPL options
    useEffect(() => {
        const getBnplOptions = async (id: string) => {
            if (id) {
                setGlobalState('loading', true);
                try {
                    const response = await axios.post<DueDaysOption[]>(
                        process.env.REACT_APP_API_BASE_URL +
                            'web/invoice/' +
                            id +
                            '/get_bnpl_options/'
                    );
                    setDueDaysOption(response.data);
                } catch (error) {
                    console.error('Error fetching BNPL options:', error);
                } finally {
                    setGlobalState('loading', false);
                }
            }
        };
        if (invoice.invoice_type === 'standard') {
            return;
        }
        getBnplOptions(invoiceId);
    }, [invoiceId]);

    // Handle click on a DaysAmount
    const handleClick = async (index: number) => {
        const selectedOption = dueDaysOptions[index];
        setSelectedDueDays(index);
        const dueDate = new Date(invoice.created ?? '');
        dueDate.setDate(dueDate.getDate() + selectedOption.due_days);
        if (invoice.uuid) {
            try {
                const { data } = await axios.post(
                    process.env.REACT_APP_API_BASE_URL +
                        'web/invoice/' +
                        invoice.uuid +
                        '/get_updated_lines/',
                    { intent_uuid: selectedOption.intent_uuid }
                );
                setGlobalState('invoice', {
                    ...invoice,
                    due_days: selectedOption.due_days,
                    due_date: dueDate,
                    total_amount: data.total_amount,
                    vat: Number(data.vat),
                    lines: data.lines,
                    intent_uuid: selectedOption.intent_uuid
                });
            } catch (error) {
                console.error('Error fetching BNPL options:', error);
            }
        }
    };

    return (
        <Wrapper>
            {loading ? (
                <AnimatedLoader />
            ) : (
                <RoundBox>
                    <Text
                        i={invoice.invoice_type === 'standard' ? 'Förfallodatum' : 'Betalsätt'}
                        fontWeight="bold"
                        header
                    />
                    {invoice.invoice_type === 'standard' && (
                        <DaysAmount selected={true}>
                            <RowView>
                                <RowView>
                                    <Dot selected={true} />
                                    <TextWrapper>
                                        <Text i={dateTransform(invoice.due_date, invoice)} />
                                    </TextWrapper>
                                </RowView>
                            </RowView>
                        </DaysAmount>
                    )}
                    {dueDaysOptions.map((item, index) => {
                        return (
                            <DaysAmount
                                selected={selectedDueDays === index}
                                key={index}
                                onClick={() => handleClick(index)}>
                                <RowView>
                                    <RowView>
                                        <Dot selected={selectedDueDays === index} />
                                        <TextWrapper>
                                            <Text
                                                fontWeight="bold"
                                                i={`${item.due_days} dagar ${
                                                    Number(item.cost_excl_vat_amount) === 0
                                                        ? ''
                                                        : iniTialTotalAmount >
                                                          CHANGE_KR_TO_PERCENTAGE
                                                        ? `(+ ${Number(
                                                              (Number(item.cost_excl_vat_amount) /
                                                                  iniTialTotalAmount) *
                                                                  100
                                                          ).toFixed(2)}%)`
                                                        : `(+ ${Number(
                                                              item.cost_excl_vat_amount
                                                          ).toFixed(2)} SEK)`
                                                }`}
                                                color={colors.dark2}
                                            />
                                            <Text
                                                i={`Förfallodatum ${addDays(
                                                    invoice.created,
                                                    dueDaysOptions[index].due_days
                                                )}`}
                                            />
                                        </TextWrapper>
                                    </RowView>
                                </RowView>
                            </DaysAmount>
                        );
                    })}
                </RoundBox>
            )}
        </Wrapper>
    );
};

export default DueDays;
